import React from 'react'

function Alert(props) {
    return (
        props.alert && <div className={`h-12 text-lg bg-${props.alert.type}-500 text-left pl-14 flex items-center fixed top-0 z-50 w-full shadow-md`}>
            <p className={`text-${props.alert.type}-900 font-semibold`}>{props.alert.message}</p>
        </div>
    )
}

export default Alert