import React, { useState } from 'react'

function Form(props) {
    const [value, setValue] = useState("");
    const handleChange = (e) => {
        setValue(e.target.value)
    }
    const handleUppercase = () => {
        setValue(value.toUpperCase())
        props.showAlert("blue", "Succesfully UPPERCASE'd the text")
    };
    const handleLowercase = () => {
        setValue(value.toLowerCase())
        props.showAlert("green", "Succesfully lowercase'D the text")
    }
        ;
    const handleTrim = () => {
        let newVal = value.split(/[ ]+/);
        setValue(newVal.join(" "));
        props.showAlert("red", "Succesfully removed extra spaces")
    }
    const handleClipboard = () => {
        navigator.clipboard.writeText(value)
        props.showAlert("green", "Text copied to Clipboard")
    };
    const getWords = () => value.replace(/\n/g, " ").split(" ").filter((val) => val !== "");
    return (
        <section className={`text-gray-200 bg-${props.mode}-800 body-font relative`}>
            <div className="container px-5 py-24 mx-auto">
                <div className="flex flex-col text-center w-full mb-12">
                    <h1 className={`sm:text-3xl text-2xl font-medium title-font mb-4 text-${props.mode}-300`}>Write Something...</h1>
                </div>
                <div className="lg:w-1/2 md:w-2/3 mx-auto">
                    <div className="flex flex-wrap -m-2">
                        <div className="p-2 w-full">
                            <div className="relative">
                                <textarea id="message" name="message" placeholder="Some text here..." value={value} onChange={handleChange} className={`w-full bg-${props.mode}-700 bg-opacity-50 rounded border border-gray-300 focus:border-indigo-500 focus:ring-2 focus:ring-indigo-200 h-32 text-base outline-none text-gray-300 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out`}></textarea>
                            </div>
                        </div>
                        <div className="p-2 w-full flex flex-wrap">
                            <button onClick={handleUppercase} className={`flex mr-2 mb-2 text-slate-700 bg-${props.mode}-300 border-0 font-semibold py-2 px-8 focus:outline-none hover:bg-${props.mode}-400 rounded text-sm`}>Uppercase</button>
                            <button onClick={handleLowercase} className={`flex mr-2 mb-2 text-slate-700 bg-${props.mode}-300 border-0 font-semibold py-2 px-8 focus:outline-none hover:bg-${props.mode}-400 rounded text-sm`}>Lowercase</button>
                            <button onClick={handleTrim} className={`flex mr-2 mb-2 text-slate-700 bg-${props.mode}-300 border-0 font-semibold py-2 px-8 focus:outline-none hover:bg-${props.mode}-400 rounded text-sm`}>Trim</button>
                            <button onClick={handleClipboard} className={`flex mr-2 mb-2 text-slate-700 bg-${props.mode}-300 border-0 font-semibold py-2 px-8 focus:outline-none hover:bg-${props.mode}-400 rounded text-sm`}>Copy</button>
                        </div>
                        <div className="p-1 w-full pt-1 mt-2 border-t border-gray-200 text-left">
                            <p className="leading-normal my-2">Word and Letter count here</p>
                            <p className="leading-normal my-2">{getWords().length} Words and {value.trim().length} Letters.</p>
                        </div>
                        <div className="p-1 w-full pt-1 mt-2 border-t border-gray-200 text-left">
                            <p className="leading-normal my-2">Estimate read...</p>
                            <p className="leading-normal my-2">{0.008 * getWords().length} minutes.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Form