import React from 'react'

function Header(props) {
    return (
        <header className={`text-white body-font px-10 bg-${props.mode}-900`}>
            <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
                <a className="flex title-font font-medium items-center text-sky-300 mb-4 md:mb-0">
                    <img src={props.src} className="w-10" alt="header logo" />
                    <span className="text-xl">Manitext</span>
                </a>
                <nav className="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
                    <a className="mr-5 hover:text-sky-300 cursor-pointer">Home</a>
                    <a className="mr-5 hover:text-sky-300 cursor-pointer">Docs</a>
                    <a className="mr-5 hover:text-sky-300 cursor-pointer">Examples</a>
                    <a className="mr-5 hover:text-sky-300 cursor-pointer">Projects</a>
                    <a className="mr-5 hover:text-sky-300 cursor-pointer">About</a>
                </nav>
                <div>
                    <button onClick={props.color} className="w-7 h-7 mx-2 inline-flex items-center bg-slate-900 border-2 p-1 rounded-full mt-4 md:mt-0"></button>
                    <button onClick={props.color} className="w-7 h-7 mx-2 inline-flex items-center bg-sky-900 border-2 p-1 rounded-full mt-4 md:mt-0"></button>
                    <button onClick={props.color} className="w-7 h-7 mx-2 inline-flex items-center bg-purple-900 border-2 p-1 rounded-full mt-4 md:mt-0"></button>
                </div>
            </div>
        </header>
    )
}

export default Header